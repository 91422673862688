import { memo, useEffect, useState } from 'react';
import { IoPlaySharp, IoPauseSharp, IoArrowBackSharp, IoArrowForwardSharp } from 'react-icons/io5';
import PropTypes from 'prop-types';

import useAudio from '../../hooks/useAudio';
import useLanguage from '../../hooks/useLanguage';

import i18n from '../../i18n/i18n';

const Player = memo(({ name, image, source }) => {
  const [trackWidth, setTrackWidth] = useState(0);
  const { curTime, duration, playing, setPlaying } = useAudio();

  const calculateTime = (secs) => {
    if (secs && !Number.isNaN(secs) && secs >= 0) {
      const minutes = Math.floor(secs / 60);
      const seconds = Math.floor(secs % 60);
      const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

      return `${returnedMinutes}:${returnedSeconds}`;
    }

    return '00:00';
  };

  const calculatePosition = () => {
    const pos = (curTime / duration) * 100;

    if (!pos || Number.isNaN(pos) || pos < 0) {
      return 0;
    }

    return pos.toFixed(2);
  };

  const playPause = () => {
    setPlaying(!playing);
  };

  useEffect(() => {
    const pos = calculatePosition();

    setTrackWidth(pos);
  }, [duration, curTime]);

  return (
    <div className='player'>
      <audio id='audio'>
        <source src={source} />
      </audio>
      <div className='player-body flex flex-row flex-vertical-center no-select'>
        <div className='player-left'>
          <div
            className='hero player-cover'
            style={{
              backgroundImage: `url('${image}')`,
            }}
          />
        </div>
        <div className='player-right pad-left'>
          <h5>{name}</h5>
          <div className='player-controls flex flex-row flex-vertical-center'>
            <button
              type='button'
              onClick={() => playPause()}
              className={playing ? 'play-button pause-button' : 'play-button'}
            >
              {playing ? <IoPauseSharp /> : <IoPlaySharp />}
            </button>
            <div className='flex flex-row flex-center pad-left'>
              <div className='pad-right'>
                <span id='position'>{calculateTime(curTime)}</span>
              </div>
              <div className='player-track'>
                <div className='player-progress' style={{ width: `${trackWidth}%` }} />
              </div>
              <div className='pad-left'>
                <span id='duration'>{calculateTime(duration)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

Player.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

const Tracks = memo(({ trackNum }) => {
  const { language } = useLanguage();

  if (language.appLanguage === 'tr') {
    return (
      <>
        {trackNum === 1 && (
          <Player
            name='Vücut tarama nefesi'
            image='https://breathhubimagecdn.azureedge.net/web/samples/sessions/tr/vucut-tarama-nefesi.jpg'
            source='https://breathhubaudiocdn.azureedge.net/try/sessions/tr/vucut-tarama-nefesi.mp3'
          />
        )}

        {trackNum === 2 && (
          <Player
            name='Kilo kaybı nefesi'
            image='https://breathhubimagecdn.azureedge.net/web/samples/sessions/tr/kilo-kaybi-nefesi.jpg'
            source='https://breathhubaudiocdn.azureedge.net/try/sessions/tr/kilo-kaybi-nefesi.mp3'
          />
        )}

        {trackNum === 3 && (
          <Player
            name='Sessiz nefes'
            image='https://breathhubimagecdn.azureedge.net/web/samples/sessions/tr/sessiz-nefes.jpg'
            source='https://breathhubaudiocdn.azureedge.net/try/sessions/tr/sessiz-nefes.mp3'
          />
        )}
      </>
    );
  }

  return (
    <>
      {trackNum === 1 && (
        <Player
          name='Freedom to be me'
          image='https://breathhubimagecdn.azureedge.net/web/samples/sessions/en/freedom-to-be-me.jpg'
          source='https://breathhubaudiocdn.azureedge.net/try/sessions/en/freedom-to-be-me.mp3'
        />
      )}

      {trackNum === 2 && (
        <Player
          name='Balance breath'
          image='https://breathhubimagecdn.azureedge.net/web/samples/sessions/en/balance-breath.jpg'
          source='https://breathhubaudiocdn.azureedge.net/try/sessions/en/balance-breath.mp3'
        />
      )}

      {trackNum === 3 && (
        <Player
          name='Visualization breath'
          image='https://breathhubimagecdn.azureedge.net/web/samples/sessions/en/visualization-breath.jpg'
          source='https://breathhubaudiocdn.azureedge.net/try/sessions/en/visualization-breath.mp3'
        />
      )}
    </>
  );
});

Tracks.propTypes = {
  trackNum: PropTypes.number.isRequired,
};

const MainSessionPlayer = memo(() => {
  const [trackNum, setTrackNum] = useState(1);

  const handleTracks = (id) => {
    setTrackNum(id);
  };

  return (
    <section className='white padding-top-half padding-bottom'>
      <div className='container'>
        <div className='sample-player'>
          <div className='row'>
            <div className='col-6 center'>
              <h4>
                {i18n.t('trySessionTitle')} <strong>{i18n.t('trySessionTitleBold')}</strong>
              </h4>
              <p>{i18n.t('trySessionDescription')}</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-6'>
              <div className='flex flex-center flex-space-between'>
                <div className='arrows prev'>
                  {trackNum > 1 && (
                    <button type='button' onClick={() => handleTracks(trackNum - 1)}>
                      <IoArrowBackSharp />
                    </button>
                  )}
                </div>

                <Tracks trackNum={trackNum} />

                <div className='arrows next'>
                  {trackNum < 3 && (
                    <button type='button' onClick={() => handleTracks(trackNum + 1)}>
                      <IoArrowForwardSharp />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-6 center'>
              <div className='dots'>
                <button
                  type='button'
                  className={trackNum === 1 ? 'dot active' : 'dot passive'}
                  onClick={() => handleTracks(1)}
                >
                  &bull;
                </button>

                <button
                  type='button'
                  className={trackNum === 2 ? 'dot active' : 'dot passive'}
                  onClick={() => handleTracks(2)}
                >
                  &bull;
                </button>

                <button
                  type='button'
                  className={trackNum === 3 ? 'dot active' : 'dot passive'}
                  onClick={() => handleTracks(3)}
                >
                  &bull;
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});

export default MainSessionPlayer;
