export default [
  { Url: 'https://webapp.breathhub.app' },
  { Url: 'https://webapp.breathhub.app/en' },
  { Url: 'https://webapp.breathhub.app/tr' },
  { Url: 'https://webapp.breathhub.app/en/try' },
  { Url: 'https://webapp.breathhub.app/tr/try' },
  { Url: 'https://webapp.breathhub.app/en/get-the-app' },
  { Url: 'https://webapp.breathhub.app/tr/get-the-app' },
  { Url: 'https://webapp.breathhub.app/en/about' },
  { Url: 'https://webapp.breathhub.app/tr/about' },
  { Url: 'https://webapp.breathhub.app/en/contact-us' },
  { Url: 'https://webapp.breathhub.app/tr/contact-us' },
  { Url: 'https://webapp.breathhub.app/en/people' },
  { Url: 'https://webapp.breathhub.app/tr/people' },
  { Url: 'https://webapp.breathhub.app/en/cookie-policy' },
  { Url: 'https://webapp.breathhub.app/tr/cookie-policy' },
  { Url: 'https://webapp.breathhub.app/en/acceptable-use-policy' },
  { Url: 'https://webapp.breathhub.app/tr/acceptable-use-policy' },
  { Url: 'https://webapp.breathhub.app/en/terms-and-conditions' },
  { Url: 'https://webapp.breathhub.app/tr/terms-and-conditions' },
  { Url: 'https://webapp.breathhub.app/en/privacy-policy' },
  { Url: 'https://webapp.breathhub.app/tr/privacy-policy' },
  { Url: 'https://webapp.breathhub.app/en/help' },
  { Url: 'https://webapp.breathhub.app/tr/help' },
  { Url: 'https://webapp.breathhub.app/help/category/abonelik-ve-faturalandirma' },
  { Url: 'https://webapp.breathhub.app/help/category/about-breath-hub' },
  { Url: 'https://webapp.breathhub.app/help/category/account-settings' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-a-ulas' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-content' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-for-organizations' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-for-teachers' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-hakkinda' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-icerigi' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-i-kullanma' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-ipuclari' },
  { Url: 'https://webapp.breathhub.app/help/category/breath-hub-tips' },
  { Url: 'https://webapp.breathhub.app/help/category/breathwork-tips' },
  { Url: 'https://webapp.breathhub.app/help/category/contact-breath-hub' },
  { Url: 'https://webapp.breathhub.app/help/category/hesap-ayarlari' },
  { Url: 'https://webapp.breathhub.app/help/category/koclar-icin-breath-hub' },
  { Url: 'https://webapp.breathhub.app/help/category/kurumlar-icin-breath-hub' },
  { Url: 'https://webapp.breathhub.app/help/category/meditasyon-ve-nefes-calismasi' },
  { Url: 'https://webapp.breathhub.app/help/category/meditation-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/category/nefes-calismasi-ipuclari' },
  { Url: 'https://webapp.breathhub.app/help/category/ne-nasil-neden' },
  { Url: 'https://webapp.breathhub.app/help/category/subscriptions-billing' },
  { Url: 'https://webapp.breathhub.app/help/category/using-breath-hub' },
  { Url: 'https://webapp.breathhub.app/help/category/what-how-why' },
  { Url: 'https://webapp.breathhub.app/help/answer/10-expert-breathwork-tips' },
  { Url: 'https://webapp.breathhub.app/help/answer/aboneligim-otomatik-olarak-yenileniyor-mu' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/aboneligim-var-ama-iceriklere-erisemiyorum-',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/aktif-aboneligimi-nasil-gorebilirim' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/aktif-abonelik-durumumu-nasil-kontrol-edebilirim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/bazi-nefes-egzersizlerinde-neden-agizdan-nefes-aliyoruz',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/bildirimleri-yonetme' },
  { Url: 'https://webapp.breathhub.app/help/answer/bilincli-baglantili-nefes-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/bir-kursun-ertesi-gunune-gecemiyorum' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/bir-meditasyon-seansi-icin-nasil-niyet-belirleyebilirim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/bir-suredir-nefes-calismasi-yapiyorum-ama-herhangi-bir-gelisme-gormedim-neden',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/breath-hub-aboneligini-nasil-satin-alabilirim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/breath-hub-a-nasil-kaydolabilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/breath-hub-baska-dilleri-destekliyor-mu' },
  { Url: 'https://webapp.breathhub.app/help/answer/breath-hub-for-beginners' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/breath-hub-for-indermediate-or-advanced-users',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/breath-hub-i-hangi-cihazlarda-kullanabilirim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/breath-hub-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/breath-hub-para-iadesi-yapiyor-mu' },
  { Url: 'https://webapp.breathhub.app/help/answer/breath-hub-partner-programi-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/breath-hub-ucretsiz-mi' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/breath-hub-uygulamasini-nasil-indirebilirim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/breath-hub-websitesinde-hangi-odeme-yontemleri-kabul-ediliyor',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/buteyko-metodu-nedir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/can-i-change-upgrade-my-current-paid-subscription',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/can-i-redeem-the-subscription-code-if-i-already-have-paid-for-a-subscription-on-my-account',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/can-i-use-the-app-offline' },
  { Url: 'https://webapp.breathhub.app/help/answer/can-i-use-the-app-on-more-than-one-device' },
  { Url: 'https://webapp.breathhub.app/help/answer/chandra-bhedana-nasil-uygulanir' },
  { Url: 'https://webapp.breathhub.app/help/answer/chandra-bhedana-nedir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/chandra-bhedana-tekniginin-faydalari-nelerdir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/covid-sonrasi-sendromu-nedir-nefes-egzersizleri-covid-19-sonrasi-tedavi-surecine-nasil-destek-olur',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/dakikada-kac-nefes-alip-vermeliyiz' },
  { Url: 'https://webapp.breathhub.app/help/answer/denge-nefesi-nedir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/dinlen-ve-sindir-tepkisi-bizim-icin-neden-onemlidir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/disfonksiyonel-nefes-aliskanliklari-sagligimizi-nasil-etkiliyor',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/disil-ve-eril-enerji-nedir-nasil-dengelenir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/diyafram-nefesi-bizim-icin-neden-faydalidir',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/does-breath-hub-offer-other-languages' },
  { Url: 'https://webapp.breathhub.app/help/answer/does-breath-hub-offer-refunds' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/does-it-matter-if-i-do-shorter-or-longer-sessions',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/does-my-subscription-automatically-renew' },
  { Url: 'https://webapp.breathhub.app/help/answer/dogru-icerigi-bulmak' },
  { Url: 'https://webapp.breathhub.app/help/answer/do-i-need-a-teacher-breathworker' },
  { Url: 'https://webapp.breathhub.app/help/answer/ekibim-icin-breath-hub-i-nasil-alabilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/ekip-ve-grup-indirimleri' },
  { Url: 'https://webapp.breathhub.app/help/answer/email-apnesi-nedir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/enerjimizi-yukseltmek-icin-hangi-nefes-teknigini-kullanabiliriz',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/e-posta-adresimi-nasil-degistirebilirim' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/farkindaligimizi-gelistirecek-mindfulness-egzersizleri-neler',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/farkli-nefes-calismasi-turleri-nelerdir' },
  { Url: 'https://webapp.breathhub.app/help/answer/finding-the-right-content' },
  { Url: 'https://webapp.breathhub.app/help/answer/gunluk-seansi-kacirirsam-ne-yapmaliyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/gunluk-seanslari-nasil-takip-edebilirim' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/hak-ve-telif-hakki-ihlallerini-kotuye-veya-yasadisi-kullanimi-nasil-bildirebilirim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/hamilelikte-nefes-almak-neden-zorlasir' },
  { Url: 'https://webapp.breathhub.app/help/answer/hesabimi-nasil-devre-disi-birakabilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/hesabimi-nasil-etkinlestirebilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/hesap-ayarlarini-nerede-bulabilirim' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/hiperventilasyona-girer-miyim-veya-bayilir-miyim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-breathing-exercises-benefit-kids' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-breathing-exercises-help-deal-with-asthma',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-breathing-exercises-help-me-deal-with-anger',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-breathing-exercises-help-me-deal-with-migraines-and-headaches',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-breathing-exercises-improve-sleep' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-breathwork-help-me-practice-mindfulness',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-covid-patients-and-patients-with-respiratory-problems-benefit-from-breathing-exercises',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-activate-my-account' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-cancel-my-free-trial' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-change-my-email-address' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-change-my-password' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-i-check-my-active-subscription-status',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-contact-customer-support' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-deactivate-my-account' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-download-breath-hub' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-follow-the-daily-sessions' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-get-breath-hub-for-my-team' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-i-get-more-information-about-the-partnership-programme',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-i-get-out-of-bed-more-easily-in-the-morning',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-i-join-the-programme-and-become-a-partner',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-i-report-violations-copyright-infringement-abuse-or-illegal-usage',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-reset-my-password' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-see-my-active-subscription' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-set-an-intention-for-meditation' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-sign-out' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-can-i-sign-up-for-breath-hub-for-organizations',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-sign-up-using-the-unique-code' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-update-the-app' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-use-the-"explore"-section' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-can-i-use-the-player' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-do-affirmations-work' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-do-breathing-exercises-help-achieve-holistic-health-and-wellbeing',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-do-breathwork-and-mindfulness-exercises-help-students-and-young-people',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-do-dysfunctional-breathing-habits-affect-our-health',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-does-breathing-affect-pain' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-does-breathing-help-create-new-neural-pathways-in-the-brain',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-does-breathwork-differ-from-meditation' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-does-breathwork-help-detoxify-and-cleanse-the-body',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-does-breathwork-help-with-creativity' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-does-free-trial-work' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-do-five-tibetan-rites-improve-wellbeing',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-do-i-get-an-invoice' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-do-i-purchase-a-breath-hub-subscription',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-do-i-purchase-a-subcsription' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-do-i-sign-in' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-do-i-sign-up' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-do-sound-baths-help-with-relaxation' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-is-breathwork-different-from-medidation',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-is-the-breath-hub-for-organizations-subscription-different-from-a-regular-breath-hub-subscription',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/how-long-should-i-practice-to-learn-breathwork',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/how-often-should-i-practice-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-to-practise-chandra-bhedana' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-to-practise-surya-bhedana' },
  { Url: 'https://webapp.breathhub.app/help/answer/how-to-sit-during-the-breathwork-practice' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-can-t-move-on-to-the-next-day-of-a-course-',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/i-can-t-sign-in' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-can-t-stop-thinking-during-the-breatwork-practices',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/i-didn-t-see-any-improvement-why' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-have-a-subscription-but-i-can-t-access-anything',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-have-trouble-concentrating-during-breathing-exercises-what-should-i-do',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-need-more-advice-regarding-my-breathwork-journey-can-i-directly-contact-a-teacher',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/is-breath-hub-free' },
  { Url: 'https://webapp.breathhub.app/help/answer/is-breathwork-better-than-meditation' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-signed-up-for-the-programme-and-my-profile-has-been-rejected',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-signed-up-for-the-programme-and-my-profile-is-activated-now-what',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/i-signed-up-for-the-programme-and-my-profile-is-in-pending-status-',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/is-there-a-particular-way-i-should-breathe' },
  { Url: 'https://webapp.breathhub.app/help/answer/i-ve-completed-basic-sessions-now-what' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/kalp-hizi-degiskenligi-sagligimiz-icin-neden-onemlidir',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/kesfet-ekrani-nasil-kullanilir' },
  { Url: 'https://webapp.breathhub.app/help/answer/koklenmek-ne-demektir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/kurumlar-icin-breath-hub-a-abonelik-seceneklerim-nelerdir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/kurumlar-icin-breath-hub-aboneliginin-breath-hub-aboneliginden-farki-nedir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/kurumlar-icin-breath-hub-a-nasil-kaydolabilirim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/kurumlar-icin-breath-hub-nedir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/kurumsal-breath-hub-hesabimin-kodunu-kullanarak-var-olan-hesabimdaki-aboneligimin-odemesini-geri-alabilir-miyim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/kurumumdan-biri-zaten-bir-breath-hub-premium-hesabina-sahipse',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/manage-notifications' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/mantra-nedir-aum-om-mantrasi-ne-anlama-gelir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/mevcut-ucretli-aboneligimi-degistirebilir-guncelleyebilir-miyim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/musteri-hizmetlerine-nasil-ulasabilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/nasil-abonelik-satin-alabilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/nasil-cikis-yapabilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/nasil-fatura-alirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/nasil-oturum-acilir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/neden-bazi-nefes-calismalarinda-nefesimizi-tutariz',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/neden-bazi-nefes-egzersizleri-sirasinda-bas-donmesi-yasiyorum',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/neden-bazi-nefes-seanslarinda-elimizi-karnimiza-koyuyoruz',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/neden-bir-breath-hub-hesabina-ihtiyacim-var',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/neden-her-gun-nefes-egzersizi-yapmaliyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/nefes-alma-agriyi-nasil-etkiler' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-beyinde-yeni-noral-yollarin-olusmasina-nasil-yardimci-olur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismalari-astimla-mucadeleye-nasil-yardimci-olabilir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismalari-bedeni-ve-zihni-arindirmaya-nasil-yardimci-olur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismalarinda-belirli-bir-nefes-alma-bicimi-var-mi',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismalarindan-once-nasil-beslenmeliyiz',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismalarinin-bilimsel-dayanagi-nedir',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-icin-en-iyi-zaman-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-kimler-icindir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-meditasyondan-daha-mi-iyidir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasina-yeni-baslayanlara-ne-onerirsiniz',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasindan-sonra-yasadigimiz-bedensel-ve-zihinsel-degisiklikler-nelerdir',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-neden-onemlidir' },
  { Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasinin-faydalari-nelerdir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasinin-kisa-vadede-sonuclari-neler',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasinin-meditasyondan-farki-nedir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasinin-suresinin-bir-onemi-var-mi',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasinin-uzun-vadeli-sonuclari-neler',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasini-ogrenmek-icin-ne-kadar-pratik-yapmam-gerekir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-sirasinda-dusunmeden-duramiyorum',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-sirasinda-neden-duygusallasiyorum',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-sirasinda-vucudumda-neler-oluyor',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-calismasi-yapmak-icin-bir-ogretmene-ya-da-rehbere-ihtiyacim-var-mi',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizim-bir-sekilde-bolunurse-ne-yapmaliyim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizinin-meditasyondan-ne-farki-var',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizi-sirasinda-konsantre-olmakta-zorlaniyorum-ne-yapmaliyim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizi-uygulamasi-sirasinda-agri-gerilim-veya-bas-donmesi-hissedersem-ne-yapmaliyim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizi-uygulamasi-sirasinda-nasil-oturulur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizi-uygulamasi-sirasinda-rahatsiz-olursam-ne-yapmaliyim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-covid-hastalarina-nasil-fayda-saglayabilir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-farkindalik-pratigine-nasil-yardimci-olur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-holistik-sifaya-kavusmaya-nasil-yardimci-olur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-migren-ve-bas-agrisindan-kurtulmama-nasil-yardimci-olur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizlerinin-cocuklara-ne-faydalari-var',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-ofkeyle-basa-cikmama-nasil-yardimci-olabilir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-sirasinda-dogru-durus-nasil-olmalidir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-sirasinda-neden-gozlerimizi-kapatiriz',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-uyku-kalitesini-artirmaya-nasil-yardimci-olur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-egzersizleri-yaraticiligi-nasil-canlandirir',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/nefesi-izlemek-ne-demektir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefesimizi-tutmak-sagligimiz-icin-neden-faydalidir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-seansi-ile-ilgili-beklentim-ne-olmali',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/nefes-yolculugum-hakkinda-daha-fazla-tavsiyeye-ihtiyacim-var-bir-kocla-dogrudan-iletisime-gecebilir-miyim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/nereden-baslamaliyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/nerede-nefes-calismasi-yapmaliyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/ne-siklikla-nefes-calismasi-yapmaliyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/ne-zaman-nefes-calismasi-yapmaliyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/olumlamalar-nasil-ise-yarar' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/on-what-type-of-device-can-i-use-breath-hub',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/orta-veya-ileri-duzey-kullanicilar-icin-breath-hub',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/oturum-acamiyorum' },
  { Url: 'https://webapp.breathhub.app/help/answer/oynaticiyi-kullanma' },
  { Url: 'https://webapp.breathhub.app/help/answer/ozel-kodu-kullanarak-nasil-kaydolabilirim' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/panik-ataga-karsi-hangi-nefes-tekniklerini-uygulayabiliriz',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/partner-olmak-icin-temel-gereksinimler-nelerdir',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/partner-programi-hakkinda-nasil-daha-fazla-bilgi-edinebilirim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/prana-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/programa-kaydoldum-ama-profilim-reddedildi' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/programa-kaydoldum-ve-profilim-aktif-hale-geldi-simdi-ne-yapmaliyim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/programa-kaydoldum-ve-profilim-beklemede-gozukuyor',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/programa-nasil-katilabilir-ve-partner-olabilirim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/sabahlari-nasil-daha-kolay-yataktan-cikabilirim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/savas-ya-da-kac-tepkisi-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/science-behind-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/seanslari-tekrar-edebilir-miyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/seanslari-ve-kurslari-kim-olusturuyor' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/ses-banyosu-nedir-rahatlamaya-nasil-yardimci-olur',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/sifremi-nasil-degistirebilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/sifremi-unuttum' },
  { Url: 'https://webapp.breathhub.app/help/answer/sig-nefes-almak-neden-zararlidir' },
  { Url: 'https://webapp.breathhub.app/help/answer/surya-bhedana-nasil-uygulanir' },
  { Url: 'https://webapp.breathhub.app/help/answer/surya-bhedana-nedir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/surya-bhedana-tekniginin-faydalari-nelerdir',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/team-and-group-discounts' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/temel-seanslari-tamamladim-simdi-ne-yapmaliyim',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/tibet-in-5-hareketi-saglik-ve-sihhatimize-nasil-faydali-olur',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/tipik-bir-nefes-calismasi-seansi-nasil-gecer',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/ucretsiz-deneme-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/ucretsiz-denemeyi-nasil-iptal-edebilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/ucuncu-goz-nedir-nasil-aktive-edilir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/uygulamayi-birden-fazla-cihazda-kullanabilir-miyim',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/uygulamayi-cevrimdisi-kullanabilir-miyim' },
  { Url: 'https://webapp.breathhub.app/help/answer/uygulamayi-nasil-guncelleyebilirim' },
  { Url: 'https://webapp.breathhub.app/help/answer/vagus-siniri-nedir' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-are-feminine-and-masculine-energies-how-can-we-balance-feminine-and-masculine-energies',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-are-mantras-what-does-the-aum-om-mantra-mean',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-are-my-options-to-sign-up-for-breath-hub-for-organizations',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-are-some-mindfulness-exercises-to-improve-our-awareness',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-are-the-benefits-of-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-are-the-benefits-of-chandra-bhedana' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-are-the-benefits-of-surya-bhedana' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-are-the-early-results-of-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-are-the-key-requirements' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-are-the-long-term-results-of-breathwork',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-are-the-types-of-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-can-I-expect-to-feel-in-a-session' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-does-a-typical-session-look-like' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-does-being-grounded-mean' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-if-i-repeat-sessions' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-if-someone-in-my-organization-already-has-a-breath-hub-premium-account',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-breath-hub' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-breath-hub-for-organizations' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-breath-hub-partnership-programme' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-breath-watching' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-chandra-bhedana' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-conscious-connected-breathing' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-email-apnoea' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-is-happening-in-my-body-during-breathwork',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-is-post-covid-syndrome-how-can-breathing-exercises-help-alleviate-post-covid-symptoms',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-prana' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-surya-bhedana' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-the-buteyko-method' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-the-correct-posture-for-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-the-fight-or-flight-response' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-is-the-third-eye-and-how-is-it-activated',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-the-vagus-nerve' },
  { Url: 'https://webapp.breathhub.app/help/answer/what-is-wim-hof-method' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-kind-of-changes-do-we-experience-after-a-breathwork-session',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-payment-methods-are-accepted-through-the-breath-hub-website',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-s-balance-breath' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-should-i-do-if-i-feel-pain-frustration-dizziness-during-a-breathwork-practice',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-should-i-do-if-i-feel-uncomfortable-during-a-breathwork-practice',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-should-i-do-if-i-miss-a-daily-session' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-should-i-do-when-i-encounter-interruptions-during-my-breathwork-practice',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/what-should-or-shouldn-t-we-eat-before-a-breathing-exercise',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/what-should-our-normal-breathing-rate-be' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/when-is-the-best-time-for-breathing-exercises',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/when-should-i-use-practice-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/where-can-i-find-the-account-settings' },
  { Url: 'https://webapp.breathhub.app/help/answer/where-should-i-practice-breathwork' },
  { Url: 'https://webapp.breathhub.app/help/answer/where-should-i-start' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/which-breathing-exercises-help-to-deal-with-panic-attack-and-anxiety',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/which-breathing-technique-can-we-use-to-increase-our-energy',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/who-creates-the-sessions-and-courses' },
  { Url: 'https://webapp.breathhub.app/help/answer/who-is-breathwork-for' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-do-i-get-very-emotional-during-a-breathing-session',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/why-do-i-need-a-breath-hub-account' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-do-i-sometimes-feel-dizzy-in-a-breathing-exercise',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-do-we-breathe-through-the-mouth-in-some-breathing-exercises',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-do-we-keep-our-eyes-closed-in-a-breathing-session',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-do-we-sometimes-hold-our-breath-in-breathing-exercises',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-is-breath-holding-beneficial-for-our-health',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/why-is-diaphragmatic-breathing-good-for-us' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-is-heart-rate-variability-important-for-our-health',
  },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-is-it-difficult-to-breathe-during-pregnancy',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/why-is-it-important' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-is-rest-and-digest-response-important-for-us',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/why-is-shallow-breathing-bad-for-us' },
  {
    Url: 'https://webapp.breathhub.app/help/answer/why-should-i-place-my-hand-on-my-belly-during-a-breathing-exercise',
  },
  { Url: 'https://webapp.breathhub.app/help/answer/why-should-i-practice-breathwork-every-day' },
  { Url: 'https://webapp.breathhub.app/help/answer/will-i-hyperventilate-or-pass-out' },
  { Url: 'https://webapp.breathhub.app/help/answer/wim-hof-metodu-nedir' },
  { Url: 'https://webapp.breathhub.app/help/answer/yeni-baslayanlar-icin-breath-hub' },
];
