import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import Sidebar from '../../components/Common/Sidebar';
import Loading from '../../components/Loading/Loading';

import Functions from '../../tools/Functions';

import i18n from '../../i18n/i18n';

const SubscriptionsScreen = () => {
  const navigate = useNavigate();

  const { language } = useLanguage();
  const { member, isMemberSignedin } = useMember();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const runAsync = async () => {
      const serverMember = await isMemberSignedin(language.appLanguage);

      if (serverMember) {
        setLoading(false);
      } else {
        navigate('/members/signin?redirect=/members/subscriptions');
      }
    };

    runAsync();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader robots={false} title={i18n.t('subscriptions')} />
      <SiteLayout headerType='white absolute' footerType='green' hideFooter>
        <section className='gray flex sidebar-container'>
          <Sidebar />
          <div className='right-content flex flex-column flex-center'>
            <div className='box min-box'>
              <div className='box-tabs'>
                <ul>
                  <li>
                    <div className='active'>{i18n.t('subscriptions')}</div>
                  </li>
                </ul>
              </div>
              {!member?.communityMemberSubscriptions?.length && (
                <div>
                  {member.subscription ? (
                    <div className='transition subs-manage'>
                      <h5>{member.subscription.name}</h5>
                      <p>
                        {i18n.t('through')}: {Functions.humanReadableDate(member.subscription.ends)}
                        &nbsp; - &nbsp;
                        <span>
                          {member.subscription.status ? i18n.t('active') : i18n.t('passive')}
                        </span>
                      </p>
                      {member.subscription.manageUrl && (
                        <>
                          <br />
                          <a
                            target='_blank'
                            rel='noopener noreferrer'
                            href={member.subscription.manageUrl}
                            className='button button-green button-large'
                          >
                            {i18n.t('manageSubscription')}
                          </a>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className='transition subs-manage'>
                      <h5>{i18n.t('noSubscription')}</h5>
                      <br />
                      <Link
                        to={`/${language.appLanguage}`}
                        className='button button-green button-large'
                      >
                        {i18n.t('tryForFree')}
                      </Link>
                    </div>
                  )}
                </div>
              )}

              {member?.communityMemberSubscriptions &&
                Array.isArray(member?.communityMemberSubscriptions) &&
                member.communityMemberSubscriptions.map((subscription) => (
                  <div key={subscription.id} className='transition subs-manage'>
                    <h5>{subscription.name}</h5>
                    <p>
                      {i18n.t('through')}: {Functions.humanReadableDate(subscription.endDate)}
                      &nbsp; - &nbsp;
                      <span>{subscription.status ? i18n.t('active') : i18n.t('passive')}</span>
                    </p>
                    <br />
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href='/manage/subscription/stripe?origin=community'
                      className='button button-green button-large'
                    >
                      {i18n.t('manageSubscription')}
                    </a>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </SiteLayout>
    </>
  );
};

export default SubscriptionsScreen;
