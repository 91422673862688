import { useState, useEffect } from 'react';
import parse from 'html-react-parser';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import ImageHeader from '../../components/Common/ImageHeader';
import GetStarted from '../../components/Sections/GetStarted';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const CreateHtmlContentArea = ({ html }) => {
  if (html) {
    return (
      <div className='html-content' style={{ paddingLeft: 5 }}>
        {parse(html)}
      </div>
    );
  }

  return null;
};

const AboutScreen = () => {
  const { language } = useLanguage();

  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(null);

  useEffect(() => {
    const runAsync = async () => {
      setLoading(true);

      const parameters = {
        method: 'GET',
        url: `v1/content/about`,
        apiType: 'contentApi',
        language: language.appLanguage,
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        setResults(request.data.results);
      }

      setLoading(false);
    };

    runAsync();
  }, [language.appLanguage]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader
        robots
        title={i18n.t('aboutSeoTitle')}
        description={i18n.t('aboutSeoDescription')}
        keywords='about, about breath hub, breath hub, breathhub, breath hub app'
        canonical={`https://webapp.breathhub.app/${language.appLanguage}/about`}
      />
      <SiteLayout headerType='white' footerType='green'>
        <ImageHeader image='https://breathhubimagecdn.azureedge.net/web/heros/premium.jpg'>
          <h1>{i18n.t('aboutTitle')}</h1>
          <p className='tight'>{i18n.t('aboutDescription')}</p>
        </ImageHeader>
        <section className='gray padding'>
          <div className='container'>
            <div className='row'>
              <div className='col-6'>
                <div className='site-content'>
                  <h1>{i18n.t('aboutH1')}</h1>
                  <CreateHtmlContentArea html={results.contents} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className='download-wrapper'>
          <GetStarted white />
        </div>
      </SiteLayout>
    </>
  );
};

export default AboutScreen;
