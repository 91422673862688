import { useState } from 'react';
import { Link } from 'react-router-dom';

import useLanguage from '../../hooks/useLanguage';

import SeoHeader from '../../components/SeoHeader/SeoHeader';
import SiteLayout from '../../components/Layouts/SiteLayout';
import ErrorMessage from '../../components/Forms/ErrorMessage';
import SuccessMessage from '../../components/Forms/SuccessMessage';
import FormInput from '../../components/Forms/FormInput';
import FormButton from '../../components/Forms/FormButton';
import Loading from '../../components/Loading/Loading';

import Request from '../../tools/Request';

import i18n from '../../i18n/i18n';

const ForgotPasswordScreen = () => {
  const { language } = useLanguage();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    emailAddress: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(null);

    setLoading(true);

    const parameters = {
      method: 'POST',
      url: 'v1/passwordreset',
      apiType: 'memberApi',
      data: {
        emailAddress: formValues.emailAddress,
      },
      language: language.appLanguage,
    };

    const request = await Request.getResponse(parameters);

    if (request.status === 200) {
      setSuccess({
        title: i18n.t('success'),
        message: request.data.title,
        buttonText: i18n.t('ok'),
      });
    } else {
      setError({ message: request.title });
    }

    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <SeoHeader
        robots
        title={i18n.t('forgotPassword')}
        description='Forgot your Breath Hub password?'
        keyword='forgot password, password forgot, reset password, breath hub, breath hub app'
        canonical='https://webapp.breathhub.app/members/forgot-password'
      />
      <SiteLayout headerType='white' footerType='green'>
        {error && <ErrorMessage message={error.message} />}
        <section className='gray member-padding member-back'>
          <div className='container'>
            <div className='box min-box'>
              <div className='box-tabs'>
                <ul>
                  <li>
                    <div className='active'>{i18n.t('forgotPassword')}</div>
                  </li>
                </ul>
              </div>
              <div className='transition'>
                <p>{i18n.t('forgotPasswordDescription')}</p>
                <form className='form' onSubmit={handleSubmit} noValidate>
                  <div className='form-elements'>
                    <div className='form-line'>
                      <label htmlFor='emailAddress'>{i18n.t('emailLabel')}</label>
                      <FormInput
                        type='email'
                        name='emailAddress'
                        value={formValues.emailAddress}
                        placeholder={i18n.t('emailPlaceholder')}
                        onChange={handleChange}
                      />
                    </div>
                    <div className='form-line right'>
                      <Link to='/members/signin'>{i18n.t('signinToYourAccount')}</Link>
                    </div>
                    <div className='form-line'>
                      <div className='buttons'>
                        <FormButton
                          type='submit'
                          text={i18n.t('resetMyPassword')}
                          onClick={handleSubmit}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        {success && (
          <SuccessMessage
            title={success.title}
            message={success.message}
            buttonText={success.buttonText}
            click={() => setSuccess(null)}
          />
        )}
      </SiteLayout>
    </>
  );
};

export default ForgotPasswordScreen;
