/* eslint-disable no-unused-vars */
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import i18n from '../../i18n/i18n';

import Request from '../../tools/Request';

const PremiumCard = memo(
  ({ subId, path, name, pricing, description, transparent, location, period }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { member } = useMember();
    const { language } = useLanguage();

    const selectSubscription = async () => {
      setLoading(true);

      const parameters = {
        method: 'GET',
        url: `v1/stripe/checkout/${subId}`,
        apiType: 'purchaseApi',
        headers: [{ AccessToken: member.token }],
        language: location === 'TR' ? 'tr' : 'en',
      };

      const request = await Request.getResponse(parameters);

      if (request.status === 200) {
        window.location.href = request.data.results.sessionUrl;
      } else {
        setLoading(false);
      }
    };

    const redirectToSignin = () => navigate(`/${language.appLanguage}/members/signup/${subId}`);

    return (
      <div className={transparent ? 'transparent-item no-select' : 'green-item no-select'}>
        <h4>
          {name}
          <br />
          <div style={{ fontWeight: 'lighter' }}> {i18n.t('premiumFreeText')}</div>
          {description && <strong>{description}</strong>}
        </h4>
        <p>{parse(pricing)}</p>
        {loading ? (
          <button type='button' className='button button-white button-mini'>
            Loading...
          </button>
        ) : (
          <button
            type='submit'
            className='button button-white button-mini purchase-card-sub-btn'
            onClick={() => (member ? selectSubscription() : redirectToSignin())}
          >
            {i18n.t('selectCard')}
          </button>
        )}
      </div>
    );
  }
);

export default PremiumCard;
