export default {
  // common
  main: 'Ana sayfa',
  about: 'Hakkımızda',
  help: 'Yardım',
  account: 'Hesabım',
  subscription: 'Breath Hub Aboneliğim',
  subscriptionCommunity: 'Topluluk Aboneliğim',
  subscriptionCommunityOneYear: '1 Yıllık Abonelik',
  subscriptionCommunityOneMonth: '1 Aylık Abonelik',
  subscriptionCommunityOneTime: 'Peşin Ödeme',
  subscriptionPerm: '3 Yıllık Abonelik',
  signout: 'Oturumu kapat',
  signin: 'Giriş yap',
  signup: 'Hesap oluştur',
  tryForFree: 'Ücretsiz dene',
  signinToYourAccount: 'Hesabına giriş yap',
  forgotPasswordLink: 'Şifreni mi unuttun?',
  contactUs: 'Bize ulaşın',
  cookiePolicy: 'Çerez politikası',
  acceptableUsePolicy: 'Kullanım koşulları',
  termsAndConditions: 'Kullanım şartları',
  privacyPolicy: 'Gizlilik sözleşmesi',
  learnMore: 'Daha fazla',
  readMore: 'Daha fazla',
  share: 'Paylaş',
  active: 'Aktif',
  passive: 'Pasif',
  ok: 'Tamam',
  error: 'Hata',
  success: 'Başarılı',
  goToHome: 'Ana sayfaya dön',
  goToSignin: 'Giriş sayfasına git',
  goToProfile: 'Hesabına git',
  goToSubscriptions: 'Abonelik ayarlarıma git',
  select: 'Seçin',
  selectCard: 'Üyeliğini başlat',
  createMeeting: 'Bizimle İletişime Geç',
  viewAll: 'Hepsini göster',
  submit: 'Gönder',
  seeAll: 'Tümünü gör',
  or: 'Ya da',
  continueWithApple: 'Apple ile devam et',
  continueWithGoogle: 'Google ile devam et',
  conflictEmailMsg: 'Bu mail adresine kayıtlı üyeliğiniz vardır, lütfen giriş yapınız.',
  goToHomePage: 'Ana sayfa',

  // Order
  orderErrorTitle: 'Sipariş iptal edildi',
  orderErrorDescription:
    'İşlem İptal edildi. Diğer üyelik modelleri ve toplulukları inceleyebilirsiniz.',
  orderSuccessTitle: 'Sipariş başarılı',
  orderSuccessDescription:
    'Breath Hub uygulamasını indirebilir, kayıt olduğun eposta adresin ve şifren ile kullanmaya başlayabilirsin. Topluluk içeriklerine erişimin için hesap bilgilerin e-posta yoluyla iletilecektir. Teşekkürler.',

  // Sidebar
  accountHome: 'Hesabım',
  editProfile: 'Profil düzenle',
  changeEmail: 'E-posta değiştir',
  changePassword: 'Şifre değiştir',
  notifications: 'Bildirimler',
  subscriptions: 'Aboneliğim',

  // Blog
  noPosts: 'Bu tag ile ilgili post bulunamadı.',
  blogDescription:
    'Dişil enerji, daha iyi bir uyku, daha sağlıklı bir hayat için tüm bilmeniz gereken tüm nefes teknikleri ve daha fazlası Breath Hub Blog`da!',

  // Members
  through: 'Şu tarihe kadar',
  noSubscription: 'Abonelik bulunamadı',
  manageSubscription: 'Aboneliği yönet',
  changeSettings: 'Kaydet',
  resetMyPassword: 'Şifremi sıfırla',
  activateMyAccount: 'Hesabımı aktive et',

  forgotPassword: 'Şifremi unuttum',
  passwordReset: 'Şifremi sıfırla',
  activateEmail: 'E-posta aktivasyonu',
  activateMembership: 'Hesap aktivasyonu',
  signedOut: `Başarıyla çıkış yaptın.`,
  readAllPolicies: 'Lütfen tüm sözleşmeleri oku ve kabul et.',
  invalidParameters:
    'Geçersiz parametreler. Lütfen aktivasyon linkini kopyalayıp tarayıcınızın adres barına yapıştırınız',

  nameLabel: 'Adın',
  namePlaceholder: 'Adını gir',
  lastnameLabel: 'Soyadın',
  lastnamePlaceholder: 'Soyadını gir',
  newEmailLabel: 'Yeni e-posta adresin',
  newEmailPlaceholder: 'Yeni e-posta adresini gir',
  newEmailConfirmLabel: 'Yeni e-posta adresi tekrarı',
  newEmailConfirmPlaceholder: 'Yeni e-posta adresini tekrar gir',
  currentPasswordLabel: 'Mevcut şifre',
  currentPasswordPlaceholder: 'Mevcut şifreni gir',
  newPasswordLabel: 'Yeni şifre',
  newPasswordPlaceholder: 'Yeni şifreni gir',
  newPasswordConfirmLabel: 'Yeni şifre tekrarı',
  newPasswordConfirmPlaceholder: 'Yeni şifreni tekrar gir',
  emailLabel: 'E-posta adresin',
  emailPlaceholder: 'E-posta adresini gir',
  passwordLabel: 'Şifren',
  passwordPlaceholder: 'Şifreni gir',
  passwordConfirmLabel: 'Şifre tekrarı',
  passwordConfirmPlaceholder: 'Şifreni tekrar gir',
  activationCodeLabel: 'Aktivasyon kodun',
  activationCodePlaceholder: 'Aktivasyon kodunu gir',
  phoneLabel: 'Telefonun',
  phonePlaceholder: 'Telefonunu gir',
  messageLabel: 'Mesajın',
  messagePlaceholder: 'Mesajını gir',

  // Try a session
  trySessionTab: 'Seans dene',
  trySessionTitle: 'Seanslarımızdan birini şimdi',
  trySessionTitleBold: 'denemeye ne dersin?',
  trySessionDescription: `Günlük hayatta farkındalığını arttırmak istiyorsan, bir seansa ücretsiz katılabilirsin.`,

  // Try a course
  tryCourseTab: 'Kurs dene',
  tryCourseTitle: 'Kurslarımızdan birini şimdi',
  tryCourseTitleBold: 'denemeye ne dersin?',
  tryCourseDescription: `Günlük hayatta farkındalığını arttırmak istiyorsan, bir kursa ücretsiz katılabilirsin.`,

  editProfileDescription: 'Buradan adını ve soyadını değiştirebilirsin.',
  changeEmailDescription:
    'Lütfen yeni e-posta adresini gir. Aktivasyon kodunu içeren onay e-postasını yeni adresine göndereceğiz.',
  changePasswordDescription:
    'Lütfen yeni şifreni gir. Başka bir yerde kullanmadığın güçlü bir şifre oluşturmanı tavsiye ederiz.',
  forgotPasswordDescription:
    'Lütfen üye olurken kullandığın e-posta adresini gir. Şifreni değiştirmek için gerekli adımları bu adrese göndereceğiz.',
  signinDescription: 'Lütfen üye olurken kullandığın e-posta adresini ve şifreni gir.',
  signupDescription: 'Lütfen e-posta adresini gir ve bir şifre oluştur.',
  // activationDescription:
  //   'Giriş yapmadan önce e-posta adresine gönderilen aktivasyon kodunu girmelisin.',
  activationDescription: 'E-mail adresinize gönderilen link ile hesabınızı onaylayınız.',
  resetPasswordDescription:
    'Lütfen yeni şifreni gir. Başka bir yerde kullanmadığın güçlü bir şifre oluşturmanı tavsiye ederiz.',

  marketingPermission: `Gizlilik ve kişisel veri koruma politikası çerçevesinde tarafıma, pazarlama ve iletişim amaçlı ileti gönderilmesine izin veriyorum.`,
  permissions1: 'Kullanım şartları ve sanal ortam üyelik sözleşmesini okudum ve onaylıyorum.',
  permissions2:
    'Gizlilik ve kişisel veri koruma politikasına uygun olarak kişisel verilerimin işlenmesine onay veriyorum.',

  // Main hero
  mainHeroParagraph1: `Breath Hub'ı hemen indirin,`,
  mainHeroParagraph2: 'nefes yolculuğunuzu bugün başlatın.',

  // Get started
  getStartedLine1: 'Daha iyi nefes ',
  getStartedLine2: 'daha iyi yaşam!',

  // Contact
  contactParagraph: 'Breath Hub kişisel dönüşüm için rehberli nefes çalışmaları uygulamasıdır.',

  // Partnerships
  partnershipsTitle: 'Breath Hub ',
  partnershipsTitleBold: 'Topluluk Üyeliği',

  // People
  peopleTitle: 'Topluluk',
  peopleTitleBold: 'Liderleri',
  peopleSeoH1: 'Breath Hub Ailesi',
  peopleSeoTitle: 'Breath Hub Ailesi | Breath Hub',
  peopleSeoDescription:
    'Farkındalığınızı destekleyen kişisel dönüşüm rehberi Breath Hub ailesi ile tanışın!',

  // Web App
  communitySeoTitle: 'Breath Hub Topluluk | Breath Hub',
  communitySeoDescription:
    'Farkındalığınızı destekleyen kişisel dönüşüm rehberi Breath Hub Topluluklar ile tanışın!',
  communityPageLink: 'Topluluk sayfası linki',

  // Premium
  premiumFreeText: 'İlk 7 gün ücretsiz',
  premiumTitleTop: 'Breath Hub’a',
  premiumTitleBottom: 'sınırsız erişim',
  premiumDescription1:
    'Nefes yolculuğunuz burada başlıyor. Hemen katılın ve Breath Hub Premium ile eksiksiz deneyimi yaşayın.',
  premiumDescription2:
    'Deneyiminizi sevdiklerinizle paylaşmak için özel paketler oluşturduk. Daha fazlasını öğrenmek için bizimle iletişime geçin.',

  // About
  aboutTitle: 'Hakkımızda',
  aboutH1: 'Biz Kimiz?',
  aboutSeoTitle: 'Biz Kimiz? | Breath Hub',
  aboutDescription: 'Misyonumuz daha iyi bir nefes ile daha iyi yaşam sağlamak.',
  aboutSeoDescription:
    'Stresi iyileştirmeye yardımcı, gevşemeyi sağlayan rehberli bir nefes meditasyon uygulaması olan Breath Hub hakkında daha fazlasını öğrenmek için tıklayın!',

  // Contact
  contactTitle: 'Bize ulaşın',
  contactDescription:
    'Breath Hub, anksiyeteyi, stresi iyileştirmeye yardımcı olan; gevşemeyi, odaklanmayı, uykuyu, zindeliği ve farkındalığı destekleyen kişisel dönüşüm için rehberli bir nefes meditasyonu uygulamasıdır.',

  // Help
  helpSearchTitle: 'Size nasıl yardım edebiliriz?',
  helpH1: 'Sıkça Sorulan Sorular',
  helpSeoTitle: 'Sıkça Sorulan Sorular | Breath Hub',
  helpSeoDescription:
    'Hesap ayarlarından, aboneliğe, içeriğinden, kullanma ipuçlarına kadar Breath Hub hakkında tüm merak ettiklerin için hemen tıkla!',
  helpSearchPlaceholder: 'Bir şey yazın',
  helpMainButton: 'Yardım ana sayfası',

  // Language selection
  languageTitle: 'Dil değiştir',
  languageDescription: 'Dili seç',
  languageButton: 'Kapat',
};
