import { memo, useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineUp, AiOutlineDown, AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import PropTypes from 'prop-types';

import { DrawerContext } from '../../contexts/DrawerContext';

import useMember from '../../hooks/useMember';
import useLanguage from '../../hooks/useLanguage';

import i18n from '../../i18n/i18n';

const Header = memo(({ type }) => {
  const navigate = useNavigate();

  const { member } = useMember();
  const { language } = useLanguage();

  const [menuOpened, setMenuOpened] = useState(false);
  const [showDrawer, setShowDrawer] = useContext(DrawerContext);

  const handleMenuOrganizer = () => {
    setMenuOpened(!menuOpened);
  };

  const handleDrawerOrganizer = () => {
    setShowDrawer(!showDrawer);

    if (showDrawer) {
      document.body.classList.remove('no-scroll');
    } else {
      document.body.classList.add('no-scroll');
    }
  };

  const handleOpenLink = (url) => {
    setShowDrawer(false);

    document.body.classList.remove('no-scroll');

    navigate(url);
  };

  return (
    <>
      <header className={type} id='pagetop'>
        <div className='container'>
          <div className='row clearfix'>
            <div className='col-2 left responsive-hide flex flex-vertical-center' />
            <div className='col-2 header-logo center'>
              <a className='logo' href='https://breathhub.app/' target='_blank' rel='noreferrer'>
                &nbsp;
              </a>
            </div>

            <div className='col-2 right responsive-hide flex flex-vertical-center flex-end'>
              <nav>
                <ul>
                  {member ? (
                    <li className='pad-right'>
                      <button
                        type='button'
                        className='header-member'
                        onClick={() => handleMenuOrganizer()}
                      >
                        {member.name} {member.lastname}
                        {menuOpened ? <AiOutlineUp /> : <AiOutlineDown />}
                      </button>

                      {menuOpened && (
                        <div className='header-member-menu'>
                          <Link to='/members'>{i18n.t('account')}</Link>
                          <Link to='/members/signout'>{i18n.t('signout')}</Link>
                        </div>
                      )}
                    </li>
                  ) : (
                    <li>
                      <Link className='button button-green button-medium' to='/members/signin'>
                        {i18n.t('signin')}
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className='col-2 right responsive-show'>
              <button
                type='button'
                className='hamburger-menu'
                onClick={() => handleDrawerOrganizer()}
              >
                <AiOutlineMenu />
              </button>
            </div>
          </div>
        </div>
      </header>
      {showDrawer && (
        <div className='drawer'>
          <div className='drawer-top'>
            <button type='button' onClick={() => handleDrawerOrganizer()}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='drawer-bottom'>
            <ul>
              <li>
                <button type='button' onClick={() => handleOpenLink(`/${language.appLanguage}`)}>
                  {i18n.t('main')}
                </button>
              </li>
            </ul>
          </div>
          <div className='drawer-member'>
            <ul>
              {member ? (
                <>
                  <li>
                    <h5>
                      {member.name} {member.lastname}
                    </h5>
                  </li>
                  <li>
                    <button type='button' onClick={() => handleOpenLink('/members')}>
                      {i18n.t('account')}
                    </button>
                  </li>
                  <li>
                    <button type='button' onClick={() => handleOpenLink('/members/signout')}>
                      {i18n.t('signout')}
                    </button>
                  </li>
                </>
              ) : (
                <li className='pad-right'>
                  <button type='button' onClick={() => handleOpenLink('/members/signin')}>
                    {i18n.t('signin')}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
});

Header.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Header;
