export default {
  // common
  main: 'Main page',
  about: 'About',
  help: 'Help',
  account: 'Account',
  subscription: 'Breath Hub Subscription',
  subscriptionCommunity: 'Web App Subscription',
  subscriptionCommunityOneYear: 'Subscribe for 1 Year',
  subscriptionCommunityOneMonth: 'Subscribe for 1 Month',
  subscriptionCommunityOneTime: 'One Time',
  subscriptionPerm: 'Subscribe for 3 Year',
  signout: 'Sign out',
  signin: 'Sign in',
  signup: 'Sign up',
  tryForFree: 'Try for free',
  signinToYourAccount: 'Sign in to your account',
  forgotPasswordLink: 'Forgot password?',
  contactUs: 'Contact us',
  cookiePolicy: 'Cookie policy',
  acceptableUsePolicy: 'Acceptable use policy',
  termsAndConditions: 'Terms and Conditions',
  privacyPolicy: 'Privacy policy',
  learnMore: 'Learn more',
  readMore: 'Read more',
  share: 'Share',
  active: 'Active',
  passive: 'Passive',
  ok: 'OK',
  error: 'Error',
  success: 'Success',
  goToHome: 'Go to home page',
  goToSignin: 'Go to sign in',
  goToProfile: 'Go to profile',
  goToSubscriptions: 'Go to subscription settings',
  select: 'Select',
  selectCard: 'Subscribe now',
  createMeeting: 'Contact with us',
  viewAll: 'View all',
  submit: 'Submit',
  seeAll: 'See all',
  or: 'Or',
  continueWithApple: 'Continue with Apple',
  continueWithGoogle: 'Continue with Google',
  conflictEmailMsg: 'You are a registered member of this e-mail address, please log in.',

  goToHomePage: 'Main Page',

  // Order
  orderErrorTitle: 'Order cancelled',
  orderErrorDescription: 'Picked the wrong subscription? Shop around then come back to pay!',
  orderSuccessTitle: 'Order successfully completed',
  orderSuccessDescription:
    'You can download Breath Hub, sign-in with your email address and password, and start using it. Your account information for accessing community membership will be sent via email. Thank you!',

  // Sidebar
  accountHome: 'Account home',
  editProfile: 'Edit profile',
  changeEmail: 'Change email',
  changePassword: 'Change password',
  notifications: 'Notifications',
  subscriptions: 'Subscriptions',

  // Try a session
  trySessionTab: 'Try a session',
  trySessionTitle: 'Try a session',
  trySessionTitleBold: 'now',
  trySessionDescription: `How about becoming more mindful in your everyday life? Try a free breathwork sessions right now`,

  // Try a course
  tryCourseTab: 'Try a course',
  tryCourseTitle: 'Try a course',
  tryCourseTitleBold: 'now',
  tryCourseDescription: `How about becoming more mindful in your everyday life? Try a free breathwork course right now`,

  // Blog
  noPosts: 'No posts yet',
  blogDescription:
    'All the breathing techniques you need to know for feminine energy, a better sleep, a healthier life and more are on the Breath Hub Blog!',

  // Members
  through: 'Through',
  noSubscription: 'No subscription found',
  manageSubscription: 'Manage subscription',
  changeSettings: 'Save',
  resetMyPassword: 'Reset password',
  activateMyAccount: 'Activate my account',

  forgotPassword: 'Forgot password',
  passwordReset: 'Reset password',
  activateEmail: 'Activate email address',
  activateMembership: 'Activate membership',
  signedOut: `You're successfully signed out.`,
  readAllPolicies: 'Please read and agree to all policies.',
  invalidParameters:
    'Invalid parameters. Please copy & paste activation link to your browser address bar.',

  nameLabel: 'Name',
  namePlaceholder: 'Enter your name',
  lastnameLabel: 'Last name',
  lastnamePlaceholder: 'Enter your last name',
  newEmailLabel: 'New email address',
  newEmailPlaceholder: 'Enter your new email address',
  newEmailConfirmLabel: 'Confirm email address',
  newEmailConfirmPlaceholder: 'Re-enter your new email address',
  currentPasswordLabel: 'Current password',
  currentPasswordPlaceholder: 'Enter your current password',
  newPasswordLabel: 'New password',
  newPasswordPlaceholder: 'Enter your new password',
  newPasswordConfirmLabel: 'Confirm new password',
  newPasswordConfirmPlaceholder: 'Re-enter your new password',
  emailLabel: 'Email address',
  emailPlaceholder: 'Enter your email address',
  passwordLabel: 'Password',
  passwordPlaceholder: 'Enter your password',
  passwordConfirmLabel: 'Confirm password',
  passwordConfirmPlaceholder: 'Re-enter your password',
  activationCodeLabel: 'Activation code',
  activationCodePlaceholder: 'Enter your activation code',
  phoneLabel: 'Phone',
  phonePlaceholder: 'Enter your phone number',
  messageLabel: 'Message',
  messagePlaceholder: 'Enter your message',

  editProfileDescription: 'You can update your name and last name here.',
  changeEmailDescription:
    'Please enter your new email address. A verification message including your activation code will sent to your new email address.',
  changePasswordDescription:
    'Please enter your new password. It is good idea to use a strong password that you are not using elsewhere.',
  forgotPasswordDescription:
    'Please enter your registered email address. Instructions for resetting your password will be sent this email address.',
  signinDescription: 'Please enter your registered email address and password.',
  signupDescription: 'Please enter your email address and set a password.',
  // activationDescription:
  //   'Before you sign in, you must activate your account with the code sent to your e-mail address.',
  activationDescription:
    'Please verify your email account with the link sent to your email address.',
  resetPasswordDescription:
    'Please enter your new password. It is good idea to use a strong password that you are not using elsewhere.',

  marketingPermission: `I'd like to receive emails about news & updates from Breath Hub`,
  permissions1: 'I have read and agree to the terms and conditions and privacy policy',
  permissions2:
    'I consent to Breath Hub processing my personal data in accordance with its privacy policy',

  // Main hero
  mainHeroParagraph1: 'Download Breath Hub and start',
  mainHeroParagraph2: 'your breath journey today.',

  // Get started
  getStartedLine1: 'Breathe better,',
  getStartedLine2: 'live better!',

  // Contact
  contactParagraph: 'Breath Hub is a guided Breathing Meditation App for personal transformation.',

  // Partnerships
  partnershipsTitle: 'Breath Hub ',
  partnershipsTitleBold: 'Web App Membership',

  // People
  peopleTitle: 'Web App',
  peopleTitleBold: 'Leaders',
  peopleSeoH1: 'Breath Hub People',
  peopleSeoTitle: 'Breath Hub People | Breath Hub',
  peopleSeoDescription:
    'Meet the Breath Hub family, a personal transformation guide that supports your awareness!',

  // Web App
  communitySeoTitle: 'Breath Hub Web App | Breath Hub',
  communitySeoDescription:
    'Meet Breath Hub Communities, your personal transformation guide that supports your awareness!',

  communityPageLink: 'Web App Page link',

  // Premium
  premiumFreeText: 'First 7 days free',
  premiumTitleTop: 'Make the most of',
  premiumTitleBottom: 'Breath Hub',
  premiumDescription1:
    'Your breath journey starts here. Start today and get the full experience with Breath Hub Premium.',
  premiumDescription2:
    'We have created exclusive packages to share your experience with your loved ones. Contact us to learn more.',

  // About
  aboutTitle: 'About',
  aboutH1: 'Who Are We?',
  aboutSeoTitle: 'Who Are We? | Breath Hub',
  aboutDescription: 'Our mission is to make the world breathe better, live better.',
  aboutSeoDescription:
    'Click to learn more about Breath Hub, a guided breathing meditation app that helps to heal stress and provides relaxation!',

  // Contact
  contactTitle: 'Contact us',
  contactDescription:
    'Breath Hub is a guided breathwork app for personal transformation, which helps to heal anxiety, stress, and supports relaxation, focus, sleep, fitness and mindfulness.',

  // Help
  helpSearchTitle: 'How can we help you?',
  helpH1: 'FAQ',
  helpSeoTitle: 'FAQ | Breath Hub',
  helpSeoDescription:
    'Click now for all your questions about Breath Hub, from account settings, subscription, content to usage tips!',
  helpSearchPlaceholder: 'Type something',
  helpMainButton: 'Go to help',

  // Language selection
  languageTitle: 'Select language',
  languageDescription: 'Please select a language below.',
  languageButton: 'Close',
};
