import { useState, useEffect } from 'react';

function useAudioPlayer() {
  const [curTime, setCurTime] = useState();
  const [duration, setDuration] = useState();
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    const audio = document.getElementById('audio');

    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);

    audio.addEventListener('loadeddata', setAudioData);

    audio.addEventListener('timeupdate', setAudioTime);

    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    };
  }, [playing]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
  };
}

export default useAudioPlayer;
